import {useFormik} from "formik";
import * as yup from "yup";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {User} from "types/user";
import NumberFormat from "react-number-format";

import ProfileSlackSvg from "components/Svg/ProfileSlackSvg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {Theme, useTheme, styled} from "@mui/material/styles";
import constants from "styles/constants";
import DeededInput from "v2/components/DeededInput";
import DeededLabel from "v2/components/DeededLabel";
import DeededSelect from "v2/components/DeededSelect_DEPRECATED";
import DeededSpace from "v2/components/DeededSpace";
import DeededCheckbox from "v2/components/DeededCheckbox";
import {useEffect, useState} from "react";
import {getProfileTC, updateProfileTC} from "redux/reducers/profileReducer";
import {useDispatch} from "react-redux";
import {UpdateProfileData} from "types/profile";
import moment from "moment-timezone";
import {useHistory} from "react-router-dom";
import {pages} from "customerPortal/pages/paths";

const TIME_PARSE_FORMAT = "HH:mm";
type currentUserType = User & {
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  email?: string;
  phone_number?: string;
  slack_id?: string | null;
  email_notifications?: boolean;
  text_notifications?: boolean;
} & {};
const schema = yup.object().shape({
  first_name: yup.string().required("Name is required"),
  last_name: yup.string().required("Name is required"),
  middle_name: yup.string(),
  email: yup.string().email().required("Email is required"),
  phone_number: yup
    .string()
    .required("Phone is required")
    .min(12, "phone number should have 10 digits"),
  slack_id: yup.string().nullable(),
  email_notifications: yup.boolean(),
  text_notifications: yup.boolean(),
  province: yup.string().required("Province is required"),
});

const ProfileGeneralPagePersonalInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useTypedSelector(
    (state) => state.AuthReducer.currentUser,
  ) as currentUserType;

  useEffect(() => {
    dispatch(getProfileTC());
  }, [dispatch]);
  const profile = useTypedSelector((state) => state.ProfileReducer.profile);
  const theme = useTheme();
  const file = useTypedSelector((state) => state.ProfileReducer.file);
  const image = useTypedSelector((state) => state.ProfileReducer.image);
  const saveEdit = useTypedSelector((state) => state.ProfileReducer.saveEdit);
  const initialValues = {
    first_name: currentUser.first_name,
    last_name: currentUser.last_name,
    middle_name:
      currentUser.middle_name === null ? "" : currentUser.middle_name,
    email: currentUser.email,
    phone_number: currentUser.phone,
    slack_id: currentUser.slack_id,
    email_notifications: false,
    text_notifications: false,
    province: profile ? profile.timezone : "",
  };

  const onSubmit = ({
    first_name,
    last_name,
    middle_name,
    email,
    phone_number,
    slack_id,
    email_notifications,
    text_notifications,
    province,
  }: typeof initialValues) => {
    const communicationMethod = () => {
      if (email_notifications && text_notifications) {
        return "Text&Email";
      } else if (email_notifications) {
        return "Email";
      } else if (text_notifications) {
        return "Text";
      } else {
        return "";
      }
    };

    let updatedData;
    if (image === null) {
      updatedData = {
        first_name,
        last_name,
        middle_name,
        email,
        phone: phone_number,
        slack_id,
        communication_method: communicationMethod(),
        timezone: province,
        userpic: null,
      };
    } else if ("data" in file && file?.data) {
      updatedData = {
        first_name,
        last_name,
        middle_name,
        email,
        phone: phone_number,
        slack_id,
        communication_method: communicationMethod(),
        timezone: province,
        userpic: file,
      };
    } else {
      updatedData = {
        first_name,
        last_name,
        middle_name,
        email,
        phone: phone_number,
        slack_id,
        timezone: province,
        communication_method: communicationMethod(),
      };
    }

    dispatch(updateProfileTC(updatedData as UpdateProfileData));
  };
  const {handleSubmit, values, errors, handleChange} = useFormik({
    validationSchema: schema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    handleChange({
      target: {value: profile?.timezone, name: "province"},
    });
  }, [handleChange, profile]);

  useEffect(() => {
    if (profile?.communication_method === "Text&Email") {
      handleChange({
        target: {
          name: "email_notifications",
          value: true,
        },
      });
      handleChange({
        target: {
          name: "text_notifications",
          value: true,
        },
      });
    } else if (profile?.communication_method === "Email") {
      handleChange({
        target: {
          name: "email_notifications",
          value: true,
        },
      });
    } else if (profile?.communication_method === "Text") {
      handleChange({
        target: {
          name: "text_notifications",
          value: true,
        },
      });
    }
  }, [handleChange, profile]);

  useEffect(() => {
    if (["Client", "Secondary Client"].includes(currentUser?.role)) {
      history.replace(pages.USER_SETTINGS.route());
    }
  }, [currentUser])

  return (
    <PersonalInformationBlock>
      {
        currentUser?.role === "Law Firm" ? (
          <PersonalInformationText>Information</PersonalInformationText>
        ) : (
          <PersonalInformationText>Personal Information</PersonalInformationText>
        )
      }
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <CustomTnput>
            <DeededLabelSpaced title={currentUser?.role === "Law Firm" ? "Name" : "First Name"}>
              <InputField
                data-testid="profile-first-name-input"
                value={values.first_name}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "first_name",
                      value: e.target.value,
                    },
                  });
                }}
                error={errors.first_name}
              />
            </DeededLabelSpaced>
          </CustomTnput>
          {
            currentUser?.role !== "Law Firm" ? (
              <CustomTnput>
                <DeededLabelSpaced title="Last Name">
                  <InputField
                    data-testid="profile-last-name-input"
                    value={values.last_name}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "last_name",
                          value: e.target.value,
                        },
                      });
                    }}
                    error={errors.last_name}
                  />
                </DeededLabelSpaced>
              </CustomTnput>
            ):
            (
              <CustomTnput>
                <DeededLabelSpaced title="Email">
                  <InputField
                    data-testid="profile-email-input"
                    value={values.email}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "email",
                          value: e.target.value,
                        },
                      });
                    }}
                    error={errors.email}
                  />
                </DeededLabelSpaced>
              </CustomTnput>
            )
            
          }
        </InputWrapper>
        <InputWrapper>
          {
            currentUser?.role !== "Law Firm" && (
            <>
                <CustomTnput>
                  <DeededLabelSpaced title="Middle Name">
                    <InputField
                      data-testid="profile-middle-name-input"
                      value={values.middle_name}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "middle_name",
                            value: e.target.value,
                          },
                        });
                      }}
                      error={errors.middle_name}
                    />
                  </DeededLabelSpaced>
                </CustomTnput>          
                <CustomTnput sx={{marginLeft: -25}}>
                  <DeededLabelSpaced title="Email">
                    <InputField
                      data-testid="profile-email-input"
                      value={values.email}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "email",
                            value: e.target.value,
                          },
                        });
                      }}
                      error={errors.email}
                    />
                  </DeededLabelSpaced>
                </CustomTnput>
            </>
            
            )
          }
        </InputWrapper>
        <InputWrapper>
          <CustomTnput>
            <PersonalInfoLabel>Phone Number</PersonalInfoLabel>
            <NumberFormat
              data-testid="profile-phone-number-input"
              error={errors.phone_number}
              customInput={InputField}
              sx={{width: "274px", marginBottom: "20px"}}
              format="###-###-####"
              value={values.phone_number}
              onChange={(e: {target: {value: string}}) => {
                handleChange({
                  target: {
                    name: "phone_number",
                    value: e.target.value.replace(/ /g, ""),
                  },
                });
              }}
            />
          </CustomTnput>

          <CustomTnput>
            <DeededLabelSpaced title="Slack ID">
              <InputField
                data-testid="profile-slack-id-input"
                InputProps={{
                  startAdornment: <ProfileSlackSvg />,
                }}
                value={values.slack_id ?? undefined}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "slack_id",
                      value: e.target.value,
                    },
                  });
                }}
                error={errors.slack_id}
              />
            </DeededLabelSpaced>
          </CustomTnput>
        </InputWrapper>
        <InputWrapper
          sx={{
            justifyContent: "unset",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          <DeededLabelSpaced
            title="Province"
            sx={{
              marginRight: "32px",
              [theme.breakpoints.down("lg")]: {
                width: "100%",
                marginRight: "0px",
              },
            }}
          >
            <DeededSelect
              boxSx={{
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                },
              }}
              sx={{
                width: 274,
                fontFamily: "Montserrat",
                fontSize: 14,
                fontWeight: 500,
                color: "#010101",
                [theme.breakpoints.down("lg")]: {
                  width: "100%",
                },
              }}
              error={errors.province as string | undefined}
              value={values.province || ""}
              onChange={(e) => {
                handleChange({
                  target: {value: "" + e.target.value, name: "province"},
                });
              }}
              displayEmpty
              inputProps={{"aria-label": "Without label"}}
              data-testid="profile-province-drop-down"
            >
              <MenuItem value={"America/Edmonton"}>Alberta (AB)</MenuItem>
              <MenuItem value={"America/Vancouver"}>
                British Columbia (BC)
              </MenuItem>
              <MenuItem value={"America/Winnipeg"}>Manitoba (MB)</MenuItem>
              <MenuItem value={"America/Moncton"}>New Brunswick (NB)</MenuItem>
              <MenuItem value={"America/Goose_Bay"}>
                Newfoundland & Labrador (NL)
              </MenuItem>
              <MenuItem value={"America/Yellowknife"}>
                Northwest Territories (NT)
              </MenuItem>
              <MenuItem value={"America/Halifax"}>Nova Scotia (NS)</MenuItem>
              <MenuItem value={"America/Resolute"}>Nunavut (NU)</MenuItem>
              <MenuItem value={"America/Toronto"}>Ontario (ON)</MenuItem>
              <MenuItem value={"America/Halifax"}>
                Prince Edward Island (PE)
              </MenuItem>
              <MenuItem value={"America/Montreal"}>Quebec (QC)</MenuItem>
              <MenuItem value={"America/Regina"}>Saskatchewan (SK)</MenuItem>
              <MenuItem value={"America/Whitehorse"}>Yukon(YT)</MenuItem>
            </DeededSelect>
          </DeededLabelSpaced>
          <Typography
            data-testid="profile-province-current-time"
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              color: constants.colors.deededGray,
              marginTop: "29px",
              marginLeft: "-13px !important",
            }}
          >
            {moment().tz(values.province)?.format(TIME_PARSE_FORMAT)} (UTC{" "}
            {moment().tz(values.province)?.utcOffset() / 60})
          </Typography>
        </InputWrapper>
        <DeededSpace y={10} />
        <Typography
          component="p"
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "29px",
            display: "flex",
            alignItems: "center",
            color: constants.font,
          }}
        >
          Notifications
        </Typography>
        <Box
          sx={{
            display: "flex",
            marginTop: "15px",
            marginLeft: "2px",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <DeededCheckbox
              label="Email"
              checked={values.email_notifications}
              onChange={(_e, checked) => {
                handleChange({
                  target: {
                    name: "email_notifications",
                    value: checked,
                  },
                });
              }}
              sx={{
                "& span": {
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px !important",
                  lineHeight: "20px",
                  color: constants.font,
                },
              }}
              data-testid="profile-email-checkbox"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <DeededCheckbox
              checked={values.text_notifications}
              onChange={(_e, checked) => {
                handleChange({
                  target: {
                    name: "text_notifications",
                    value: checked,
                  },
                });
              }}
              sx={{
                "& span": {
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px !important",
                  lineHeight: "20px",
                  color: constants.font,
                },
              }}
              label="Text"
              data-testid="profile-text-checkbox"
            />
          </Box>
        </Box>
        <SaveButton data-testid="profile-save-changes-button" disabled={saveEdit} type={"submit"}>
          Save Changes
        </SaveButton>
      </Form>
    </PersonalInformationBlock>
  );
};
const InputField = styled(DeededInput)<{theme?: Theme}>(({theme}) => ({
  width: "274px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));
const DeededLabelSpaced = styled(DeededLabel)(({theme}) => ({
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    marginRight: "0",
  },
}));

const PersonalInfoLabel = styled("label")({
  marginLeft: "7px",
  marginBottom: "6px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "15px",
  display: "flex",
  color: constants.font,
});

const PersonalInformationText = styled(Typography)(({theme}) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "24px",
  color: constants.font,
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const Form = styled("form")(({theme}) => ({
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const InputWrapper = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const CustomTnput = styled(Box)(({theme}) => ({
  width: "274px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
    width: "100%",
  },
}));

const PersonalInformationBlock = styled(Box)(({theme}) => ({
  marginLeft: "69px",
  width: "568px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: "0px",
  },
}));

const SaveButton = styled(Button)(({theme}) => ({
  float: "right",
  height: "43px",
  width: "175px",
  backgroundColor: "var(--red)",
  border: "1px solid #F16764",
  boxSizing: "border-box",
  borderRadius: "5px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "17px",
  color: "#FFFFFF",
  "&:hover": {
    color: "var(--red)",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default ProfileGeneralPagePersonalInfo;
