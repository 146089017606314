import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Box, styled, useTheme } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import useUser from "utils/hooks/useUser";
import { CloseIcon, MenuIcon, SecurityIcon, SettingsIcon } from "components/CommonDashboard/Icons";
import Button from "components/CommonDashboard/Button";
import Colors from "components/CommonDashboard/Styling/Colors";
import HeaderMenu, { HeaderMenuItem } from "components/CommonDashboard/HeaderMenu";
import { ButtonLargeText } from "components/CommonDashboard/Typography";

export const HEADER_SIZE = {
    default: "10.3rem",
    mobile: "8.7rem",
};

interface MenuProps {
    onMenuClick: () => void;
    sideMenuOpen: boolean;
}

const Header: FC<MenuProps> = ({ onMenuClick, sideMenuOpen }) => {
    const theme = useTheme();
    const { push } = useHistory();
    const { user, isStaff } = useUser();

    return (
        <HeaderWrapper>
            <Button
                typeOf="outline"
                size="small"
                onClick={onMenuClick}
                startIcon={sideMenuOpen ? <CloseIcon /> : <MenuIcon />}
                sx={{
                    [theme.breakpoints.up("mobile")]: {
                        display: "none !important",
                    },
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    maxHeight: "100%",
                    overflow: "hidden",
                }}
            >
                <HeaderMenu 
                    name={
                        user.role === "Law Firm"
                        ? user.first_name
                        : `${user.first_name} ${user.last_name}`
                    }
                    role={isStaff ? user.role : undefined}
                    >

                    <HeaderMenuItem onClick={() => push("/dashboard/profile?page=General")}>
                        <ListItemIcon>
                            <SettingsIcon color={Colors.DARK_GREY} />
                        </ListItemIcon>
                        <ButtonLargeText color={Colors.DARK_GREY}>General</ButtonLargeText>
                    </HeaderMenuItem>
                    <HeaderMenuItem onClick={() => push("/dashboard/profile?page=Security")}>
                        <ListItemIcon>
                            <SecurityIcon color={Colors.DARK_GREY} />
                        </ListItemIcon>
                        <ButtonLargeText color={Colors.DARK_GREY}>Security</ButtonLargeText>
                    </HeaderMenuItem>
                </HeaderMenu>
            </Box>
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled(Box)(({ theme }) => ({
    position: "fixed",
    width: "100%",
    zIndex: 11,
    top: "0",
    right: "0",
    height: HEADER_SIZE.default,
    background: Colors.WHITE,
    display: "flex",
    alignItems: "center",
    padding: "1.2rem 4.8rem",
    justifyContent: "flex-end",
    [theme.breakpoints.down("mobile")]: {
        justifyContent: "space-between",
        padding: "1.2rem 2.4rem",
        height: HEADER_SIZE.mobile,
    },
}));

export default Header;
