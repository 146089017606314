import React, {SetStateAction, useEffect, useRef, useState} from "react";
import {Box, styled, MenuItem, useTheme, Typography} from "@mui/material";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import ReactAvatarEditor from "react-avatar-editor";
import {Staff} from "types/Config/userManagerTypes";
import userIcon from "assets/img/user.png";
import emailIcon from "assets/img/email.png";
import phoneIcon from "assets/img/phone.png";
import homeIcon from "assets/img/home.png";
import slackIcon from "assets/img/slack-new-logo.svg";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {enableFileFormatsIntake} from "utils/enableFileFormatsIntake";
import {returnConvertedFromHeicFile} from "utils/returnConvertedFromHeicFile";
import useWindowWidth from "utils/hooks/useWindowWidth";
import {getFormattedPostalCode} from "utils/getFormattedPostalCode";
import { ReusableSelect } from "utils/reusableSelect";
import {
  editUser,
  getAllRoles,
  updateUsers,
  restoreUser,
  mfaEnableTC,
} from "redux/reducers/configReducer/userManagerReducer";
import {UserManagerType} from "redux/reducers/types/userManagerTypes";
import {addErrorAC, addSuccessMessageAC} from "redux/reducers/ErrorReducer";
import constants from "styles/constants";
import DefaultImageUploadIcon from "components/Svg/DefaultImageUploadIcon";
import {baseURL, sendEmailForReset} from "api";
import DeededCheckbox from "v2/components/DeededCheckbox";
import DeededSelectV2Transparent from "v2/components/DeededSelect";
import DeededSwitcher from "v2/components/DeededSwitcher";
import DeededButton from "v2/components/DeededButton";
import DeededTextArea from "v2/components/DeededTextArea";
import useGetFirmForList from "utils/hooks/useGetLawFirmForList";

const phoneNumberValidation = /\d{3}-\d{3}-\d{4}/;
const postalCodeValidation = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
interface UserPopupsProps {
  role: string;
  user: Staff;
  closeHandler: () => void;
  setResetPage: React.Dispatch<SetStateAction<boolean>>;
}

const UserPopups: React.FC<UserPopupsProps> = ({
  role,
  user,
  closeHandler,
  setResetPage,
}) => {
  const dispatch = useDispatch();
  const allRoles = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.allRoles,
  );
  useEffect(() => {
    dispatch(getAllRoles(role));
  }, [dispatch, role]);
  const windowWidth = useWindowWidth();
  const isStaffOrProffRole = (role: string) =>
    role === "staff" || role === "professionals";
  const isStaffRole = (role: string) =>
    role === "staff";

  const isUser = (user: Staff) => user.hasOwnProperty("id");
  const SignupSchema = yup.object().shape({
    first_name: yup.string().required("Field is required"),
    last_name: yup.string().required("Field is required"),
    email: yup.string().email("Invalid email").required("Field is required"),
    phone: yup
      .string()
      .required("Phone is required")
      .min(12, "phone number should have 10 digits")
      .test("phone", "Field doesn't match the format", (phone) => {
        return phoneNumberValidation.test(phone as string);
      }),
    role: yup.string().required("Field is required"),
    law_firm: yup
      .number()
      .when('role', {
        is: 'Lawyer',
        then: (schema) => schema
          .required("Law Firm name is required")
      }),
    address: yup
      .string()
      .when('role', {
        is: 'Lawyer',
        then: (schema) => schema
          .required("Address is required")
      }),
    city: yup
      .string()
      .when('role', {
        is: 'Lawyer',
        then: (schema) => schema
          .required("City is required")
      }),
    code: yup
      .string()
      .when('role', {
        is: 'Lawyer',
        then: (schema) => schema
          .required("Postal Code is required")
      })
      .test("Postal Code", "Field doesn't match the format", (code) => {
        return code ? postalCodeValidation.test(code as string) : true;
      }),
    state: yup
      .string()
      .when('role', {
        is: 'Lawyer',
        then: (schema) => schema
          .required("Province is required")
      }),
    fax: yup
      .string()
      .when('role', {
        is: 'Lawyer',
        then: (schema) => schema
          .required("Fax is required")
          .min(12, "Fax number should have 10 digits")
      })
      .test("Fax", "Field doesn't match the format", (fax) => {
        return fax ? phoneNumberValidation.test(fax as string) : true;
      }),
  });
  const {handleSubmit, values, errors, handleChange, setValues} = useFormik({
    initialValues: {
      has_personal_page: isUser(user) ? Boolean(user.partner_page) : true,
      communication_method: "email",
      brokerage_or_team: isUser(user) ? user.brokerage_or_team : "",
      company: isUser(user) ? user.company : "",
      first_name: isUser(user) ? user.first_name || "" : "",
      middle_name: isUser(user) ? user.middle_name || "" : "",
      last_name: isUser(user) ? user.last_name || "" : "",
      email: isUser(user) ? user.email || "" : "",
      phone: isUser(user) ? user.phone || "" : "",
      slackID: isUser(user) ? user.slack_id || "" : "",
      role: isUser(user) ? (user.role !== "Deleted" ? user.role : "") : "",
      law_firm: isUser(user) ? user.law_firm || "" : "",
      userpic: null as null | {
        name: string;
        size: number;
        type: string;
        data: string | ArrayBuffer | null;
      },
      address: isUser(user) ? user.address?.address || "" : "",
      city: isUser(user) ? user.address?.city || "" : "",
      state: isUser(user) ? user.address?.state || "" : "",
      code: isUser(user) ? user.address?.code || "" : "",
      fax: isUser(user) ? user.address?.fax || "" : "",
      isTurnedOnMfa: !!user?.google_verify,
      special_condition: user.special_condition,
      permit_access: typeof user?.permit_access === 'undefined' ? true : user.permit_access,
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      console.log("Submitting values:", values);
      console.log("Role:", role);
      let valuesCopy;
      if (!values.userpic) {
        let {userpic, ...valuesWithoutUserPic} = values;
        valuesCopy = valuesWithoutUserPic;
      } else {
        valuesCopy = {...values};
      }
    
      if (role === "Law Firm") {
        dispatch(updateUsers(valuesCopy as unknown as UserManagerType, role));
      } else if (isUser(user)) {
        if (!user.deleted_at) {
          dispatch(editUser(valuesCopy, user.id as number, role));
          dispatch(mfaEnableTC(user.id as number, values.isTurnedOnMfa));
        } else {
          dispatch(restoreUser(valuesCopy, user.id as number, role));
        }
      } else {
        dispatch(updateUsers(valuesCopy as unknown as UserManagerType, role));
      }
      closeHandler();
      setResetPage(true);
      setSaveEdit(true);
    },
  });

  const [selectedRole, setSelectedRole] = useState<string | undefined>(user.role);
  
  const handleChangePostalCode = (e: {
    target: {value: string; name: string};
  }) => {
    const {value, name} = e.target;
    const formatted = getFormattedPostalCode(value as string);
    handleChange({
      target: {
        name: name,
        value: formatted,
      },
    });
  };

  const handleSelectHtmlTemplate = (selectedTemplate: PointerEvent & {target: {value: number}}) => {
    const newRole = selectedTemplate.target?.value as unknown as string;
    setSelectedRole(newRole);
    handleChange({
      target: {
        name: "role",
        value: newRole,
      },
    });
  };

  const initialRole = useRef(values.role);
  useEffect(() => {
    if (initialRole.current === "Lawyer" && values.role !== "Lawyer") {
      setValues((prevValues) => ({
        ...prevValues,
        law_firm: "",
        address: "",
        city: "",
        state: "",
        code: ""
      }));
      setSelectedLawFirm(undefined);
    }
  }, [values.role, setValues]);
  
  



  const [lawfirmslist] = useGetFirmForList();
  


  const transformedLawFirmsList = lawfirmslist?.map((firm) => ({
    label: firm.full_name.trim(),
    value: firm.id,
    address: firm.address?.address,
    city: firm.address?.city,
    code: firm.address?.code,
    state: firm.address?.state,
  }));

  const selectedLawFirmValue = user.law_firm;

  console.log("selectedLawFirmValue", selectedLawFirmValue);
  //si funciona
  const selectedLawFirmLabel = transformedLawFirmsList?.find(firm => {
    return firm.value.toString() === selectedLawFirmValue;
  })?.label || "";
  
  console.log("selectedLawFirmLabel", selectedLawFirmLabel);


  const [selectedLawFirm, setSelectedLawFirm] = useState<{ label: string; value: number } | undefined>(undefined);

  useEffect(() => {
    if (selectedLawFirmValue && selectedLawFirmLabel) {
      setSelectedLawFirm({
        label: selectedLawFirmLabel,
        value: Number(selectedLawFirmValue),
      });
    }
  }, [selectedLawFirmValue, selectedLawFirmLabel]);
  
  console.log("selectedLawFirm", selectedLawFirm);

  useEffect(() => {
    if (transformedLawFirmsList && user.law_firm && !selectedLawFirm) {
      const initialLawFirm = transformedLawFirmsList.find((firm) => firm.value === user.law_firm);
      if (initialLawFirm) {
        setSelectedLawFirm(initialLawFirm);
      } else {
      }
    }
  }, [transformedLawFirmsList, user.law_firm, selectedLawFirm]);

  const handleLawFirmSelect = (selected: { label: string; value: number, address: string, city: string, state: string, code: number }) => {
    setSelectedLawFirm(selected);
    
    setValues((prevValues) => ({
      ...prevValues,
      law_firm: selected.value.toString(),
      address: selected.address || "",
      city: selected.city || "",
      state: selected.state || "",
      code: String(selected.code)
    }));
    
    console.log({
      label: selected.label,
      value: selected.value,
      address: selected.address,
      city: selected.city,
      state: selected.state,
      code: selected.code
    });
  };

  const [saveEdit, setSaveEdit] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleUpdateImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files) {
      let evt = await returnConvertedFromHeicFile(e.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onloadend = () => {
        setValues((prevValues) => ({
          ...prevValues,
          userpic: {
            name: evt.name,
            size: evt.size,
            type: evt.type,
            data: reader.result,
          },
        }));
      };

      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const [position, setPosition] = useState({x: 0.1, y: 0.1});
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);
  const handlePositionChange = (position: {x: number; y: number}) => {
    setPosition((prev) => ({...prev, ...position}));
  };
  const handleScale = (e: {target: {value: string}}) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const theme = useTheme();
  const { sm } = theme.breakpoints.values;

  return (
    <UserForm onSubmit={handleSubmit}>
      <FieldsBox>
        <UserFormLeft>
          <UserFormLabel className="has_icon">
          {role === "law-firm" ? "Name" : "First Name"}
            <UserFormInput
              type="text"
              name="first_name"
              id="first_name"
              data-testid="add-user-first-name-input"
              onChange={handleChange}
              value={values.first_name}
              className={errors.first_name ? "has_error" : ""}
            />
            {errors.first_name ? (
              <UserFormInputError>{errors.first_name}</UserFormInputError>
            ) : null}
          </UserFormLabel>
          {role != "law-firm" ? (
            <>
              <UserFormLabel className="has_icon">
                Middle Name
                <UserFormInput
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  data-testid="add-user-middle-name-input"
                  onChange={handleChange}
                  value={values.middle_name}
                />
              </UserFormLabel>
            </>
          ) : null}
          {role !== "law-firm" ? (
            <>
              <UserFormLabel className="has_icon">
                Last Name
                <UserFormInput
                  type="text"
                  name="last_name"
                  id="last_name"
                  data-testid="add-user-last-name-input"
                  onChange={handleChange}
                  value={values.last_name}
                  className={errors.last_name ? "has_error" : ""}
                />
                {errors.last_name ? (
                  <UserFormInputError>{errors.last_name}</UserFormInputError>
                ) : null}
              </UserFormLabel>
            </>
          ) : (
            (() => {
              values.last_name = "LF";
              return null;
            })()
          )}

          {isUser(user) &&
            user?.role === "Client" &&
            windowWidth >= sm &&
            !!user?.google_verify_finished && (
              <EnableMfa
                handleChange={handleChange}
                isTurnedOnMfa={values.isTurnedOnMfa}
                user={user}
              />
            )}
          {role === "professionals" ? (
            <>
              <UserFormLabel className="has_icon email_icon">
                Brokerage or Team
                <UserFormInput
                  type="text"
                  name="brokerage_or_team"
                  id="brokerage_or_team"
                  data-testid="add-user-brokerage-or-team-input"
                  onChange={handleChange}
                  value={values.brokerage_or_team as string}
                />
              </UserFormLabel>
            </>
          ) : null}

          {isStaffOrProffRole(role) || role === "law-firm" ?(
            <UserFormLabel className="has_icon phone_icon">
              Mobile Number
              {/*@ts-ignore*/}
              <NumberFormat
                id="phone"
                data-testid="add-user-phone-input"
                className={errors.phone ? "number_input has_error" : "number_input"}
                name="phone"
                value={values.phone}
                format="###-###-####"
                onChange={handleChange}
              />
              {errors.phone ? (
                <UserFormInputError>{errors.phone}</UserFormInputError>
              ) : null}
            </UserFormLabel>
          ) : null}
          {isStaffOrProffRole(role) ? (
            <UserFormLabel>
              Role

              <WrapperSelect
                sx={{
                  "& > div": {
                    width: "100%",
                  },
                }}
              >
                <DeededSelectV2Transparent
                  onChange={handleSelectHtmlTemplate as (e?: unknown) => void}
                  value={values.role}
                  error={errors.role}
                  hideError={false}
                  sx={{
                    width: "100% !important",
                    height: "36px !important",
                    maxWidth: "227px",
                    [theme.breakpoints.down("sm")]: {
                      maxWidth: "unset",
                      width: "100% !important",
                    },
                  }}
                  data-testid="add-user-role-drop-down"
                >
                  {allRoles.map((el) => (
                    <MenuItem key={el} value={el}>
                      <Box
                        sx={{
                          display: "inline-block",
                          maxWidth: "100%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        title={el}
                        data-testid="add-user-role-drop-down-value"
                      >
                        {el}
                      </Box>
                    </MenuItem>
                  ))}
                </DeededSelectV2Transparent>
              </WrapperSelect>
              {errors.role ? (
                <UserFormInputError>{errors.role}</UserFormInputError>
              ) : null}
            </UserFormLabel>
          ) : role === "law-firm" ? (
            (() => {
              values.role = "Law Firm";
              return null;
            })()
          ) : null}

          {role != "law-firm"  && values.role === "Lawyer" ? (
            <>
              <UserFormLabel>
                Law Firm     
                <ReusableSelect
                  defaultStyles={false}
                  defaultValue={selectedLawFirm ? { ...selectedLawFirm, value: String(selectedLawFirm.value) } : undefined}
                  options={transformedLawFirmsList || []}
                  onChangeHandler={handleLawFirmSelect}
                />
                {errors.law_firm && <UserFormInputError>{errors.law_firm}</UserFormInputError>}
              </UserFormLabel>
            </>
          ) : null}
          {isStaffRole(role) || role === 'law-firm'  ? (
            <UserFormLabel className="has_icon home_icon">
              Address
              {/*@ts-ignore*/}
              <UserFormInput
                type="text"
                id="address"
                data-testid="add-user-address-input"
                className={
                  errors.address ? "number_input has_error" : "number_input"
                }
                name="address"
                value={values.address}
                onChange={handleChange}
              />
              {errors.address ? (
                <UserFormInputError>{errors.address}</UserFormInputError>
              ) : null}
            </UserFormLabel>
          ) : null}

          {isStaffRole(role) || role === 'law-firm'  ? (
            <UserFormLabel className="has_icon home_icon">
              Province
              {/*@ts-ignore*/}
              <UserFormInput
                type="text"
                id="state"
                data-testid="add-user-state-input"
                className={
                  errors.state ? "number_input has_error" : "number_input"
                }
                name="state"
                value={values.state}
                onChange={handleChange}
              />
              {errors.state ? (
                <UserFormInputError>{errors.state}</UserFormInputError>
              ) : null}
            </UserFormLabel>
          ) : null}

        
        </UserFormLeft>
        <UserFormRight>
        {role != "law-firm" ? (
            <>
              <UserFormUploadPhotoWrap className={imageUrl ? "avatarEditor" : ""}>
                <UserFormUploadPhotoImg>
                  {user?.userpic?.id && !imageUrl ? (
                    <img
                      src={`${baseURL}/userpics/${
                        user?.userpic!.id
                      }?t=${Date.now()}`}
                      alt="Profile"
                    />
                  ) : imageUrl && !saveEdit ? (
                    <div className="staff__canvas">
                      <ReactAvatarEditor
                        scale={scale}
                        width={54}
                        height={54}
                        position={position}
                        onPositionChange={handlePositionChange}
                        borderRadius={5}
                        image={imageUrl}
                        className="editor-canvas"
                        ref={editorRef}
                      />

                      <input
                        name="scale"
                        type="range"
                        onChange={handleScale}
                        min="1"
                        max="2"
                        step="0.01"
                        defaultValue="1"
                      />
                    </div>
                  ) : (
                    <DefaultImageUploadIcon />
                  )}
                </UserFormUploadPhotoImg>
                <UserFormUploadPhotoButton>
                  Upload image
                  <input
                    type="file"
                    name="upload"
                    className="file__input"
                    placeholder="Upload File"
                    accept={enableFileFormatsIntake}
                    onChange={(e) => {
                      handleUpdateImageUpload(e);
                    }}
                  />
                </UserFormUploadPhotoButton>
              </UserFormUploadPhotoWrap>
            </>
          ) : null}
          <UserFormLabel className="has_icon email_icon">
            Email
            <UserFormInput
              type="text"
              name="email"
              id="email"
              data-testid="add-user-email-input"
              onChange={handleChange}
              value={values.email}
              className={errors.email ? "has_error" : ""}
            />
            {errors.email ? (
              <UserFormInputError>{errors.email}</UserFormInputError>
            ) : null}
          </UserFormLabel>
          {role === "professionals" ? (
            <>
              <UserFormLabel className="has_icon email_icon">
                Company
                <UserFormInput
                  type="text"
                  name="company"
                  id="company"
                  data-testid="add-user-company-input"
                  onChange={handleChange}
                  value={values.company as string}
                />
              </UserFormLabel>
            </>
          ) : null}
          {isStaffRole(role) ? (
            <UserFormLabel className="has_icon phone_icon">
              Fax
              {/*@ts-ignore*/}
              <NumberFormat
                id="fax"
                data-testid="add-user-fax-input"
                className={
                  errors.fax ? "number_input has_error" : "number_input"
                }
                name="fax"
                value={values.fax}
                format="###-###-####"
                onChange={handleChange}
              />
              {errors.fax ? (
                <UserFormInputError>{errors.fax}</UserFormInputError>
              ) : null}
            </UserFormLabel>
          ) : null}
          {role !="law-firm" ? (
            <UserFormLabel className="has_icon slack_icon">
              Slack ID
              <UserFormInput
                type="text"
                name="slackID"
                id="slackID"
                data-testid="add-user-slack-id-input"
                onChange={handleChange}
                value={values.slackID}
              />
            </UserFormLabel>
          ) : null}

        {isStaffRole(role) || role === 'law-firm'  ? (
            <UserFormLabel className="has_icon home_icon">
              City
              {/*@ts-ignore*/}
              <UserFormInput
                id="city"
                type="text"
                data-testid="add-user-city-input"
                className={
                  errors.city ? "number_input has_error" : "number_input"
                }
                name="city"
                value={values.city}
                onChange={handleChange}
              />
              {errors.city ? (
                <UserFormInputError>{errors.city}</UserFormInputError>
              ) : null}
            </UserFormLabel>
          ) : null}

          {isStaffRole(role) || role == "law-firm"?(
            <UserFormLabel className="has_icon home_icon">
              Postal Code
              {/*@ts-ignore*/}
              <UserFormInput
                id="code"
                type="text"
                data-testid="add-user-code-input"
                className={
                  errors.code ? "number_input has_error" : "number_input"
                }
                name="code"
                value={values.code}
                onChange={handleChangePostalCode}
              />
              {errors.code ? (
                <UserFormInputError>{errors.code}</UserFormInputError>
              ) : null}
            </UserFormLabel>
          ) : null}

          {isUser(user) &&
            (user?.role !== "Client" || windowWidth < sm) &&
            !!user?.google_verify_finished && (
              <EnableMfa
                handleChange={handleChange}
                isTurnedOnMfa={values.isTurnedOnMfa}
                user={user}
              />
            )}

          <PermitAccess
            handleChange={handleChange}
            permit_access={values.permit_access}
            user={user}
            role={role}
          />
        </UserFormRight>
      </FieldsBox>
      {role === "professionals" && (
        <SpecialConditions values={values} handleChange={handleChange} />
      )}
      <SubmitButtons
        role={role}
        values={values}
        handleChange={handleChange}
        user={user}
        closeHandler={closeHandler}
        
      />
    </UserForm>
  );
};
const SpecialConditions: React.FC<{
  values: {special_condition?: string};
  handleChange: (someToChange: unknown) => void;
}> = ({values, handleChange}) => {
  const [isShowedTextArea, setIsShowedTextArea] = useState(false);
  const [specialConditionsError, setSpecialConditionsError] = useState<
    string | null
  >(null);
  const [text, setText] = useState(values.special_condition ?? "");
  const saveSpecialConditions = () => {
    handleChange({target: {name: "special_condition", value: text}});
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "0px 7px",
          marginTop: "24px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {values.special_condition && !isShowedTextArea && (
          <Typography
            sx={{
              width: "100%",
              overflowWrap: "anywhere",
              backgroundColor: constants.colors.redVeryPale,
              padding: "8px 12px",
              borderRadius: "5px",
              fontSize: "14px",
              color: constants.colors.red,
              whiteSpace: "normal",
              maxWidth: "100%",
              marginBottom: "24px",
            }}
          >
            {values.special_condition}
          </Typography>
        )}
        {isShowedTextArea && (
          <Box sx={{width: "100%", marginBottom: "24px"}}>
            <DeededTextArea
              sx={{
                "&.MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input":
                  {
                    overflowY: "scroll !important",
                    paddingRight: "5px",
                    "&::-webkit-scrollbar": {
                      width: "2px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "10px",
                    },
                  },
              }}
              error={specialConditionsError as unknown as string | undefined}
              height={103}
              text={text}
              setText={(text: string) => {
                if (text.length <= 300) {
                  setSpecialConditionsError(null);
                  setText(text);
                } else {
                  setSpecialConditionsError(
                    "You have exceeded the character limit",
                  );
                }
              }}
            />
          </Box>
        )}
        <DeededButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (isShowedTextArea) {
              saveSpecialConditions();
            }
            setIsShowedTextArea(!isShowedTextArea);
          }}
          sx={{
            width: "224px",
            height: "40px",
          }}
        >
          {isShowedTextArea
            ? "Save Special Conditions"
            : values.special_condition
            ? "Edit Special Conditions"
            : "Add Special Conditions"}
        </DeededButton>
      </Box>
    </>
  );
};
const SubmitButtons: React.FC<{
  role: string;
  values: {has_personal_page: boolean};
  handleChange: (someToChange: unknown) => void;
  user: Staff;
  closeHandler: () => void;
}> = ({role, values, handleChange, user, closeHandler}) => {
  console.log("values", values);
  const dispatch = useDispatch();
  const handleResetPassword = () => {
    sendEmailForReset(user.email ?? "")
      .then(() => {
        dispatch(
          addSuccessMessageAC("We have sent you email for new password"),
        );
        closeHandler();
      })
      .catch((error) => {
        const res = error as {response: {data: {error: string}}};
        dispatch(addErrorAC(res.response.data.error));
      });
  };
  const isUser = (user: Staff) => user.hasOwnProperty("id");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {role === "professionals"? (
            <DeededCheckboxWrapper>
              <DeededCheckbox
                checked={values.has_personal_page as boolean}
                onChange={handleChange}
                name="has_personal_page"
                id="has_personal_page"
                label="Generate referring link"
                data-testid="generate-referring-link-checkbox"
              />
            </DeededCheckboxWrapper>
          ) : null}
        </Box>

        <Box sx={{display: "flex"}}>
          {isUser(user) ?(
            <UserFormButton
              className="green"
              type="button"
              onClick={handleResetPassword}
            >
              Reset Password
            </UserFormButton>
          ):null}
          <UserFormButton type="submit" data-testid="submit-button">Submit</UserFormButton>
        </Box>
      </Box>
    </>
  );
};
const EnableMfa: React.FC<{
  user: Staff;
  isTurnedOnMfa: boolean;
  handleChange: (someToChange: unknown) => void;
}> = ({user, isTurnedOnMfa, handleChange}) => {
  const isUserClient = user.role === "Client";
  const theme = useTheme();
  const handleSwitch = (e: {target: {checked: boolean}}) => {
    handleChange({
      target: {
        name: "isTurnedOnMfa",
        value: e.target.checked,
      },
    });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: isUserClient ? "227px" : "217px",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "33px",
          marginBottom: isUserClient ? "0px" : "20px",
          "& .MuiFormControlLabel-root": {
            marginRight: "0px",
            marginLeft: "0px",
            "& .MuiSwitch-root": {
              margin: "0",
            },
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginTop: "0px",
            marginBottom: "20px",
          },
        }}
      >
        <Typography
          sx={{
            whiteSpace: "nowrap",
            fontSize: isUserClient ? "11px" : "10px",
            fontWeight: "600",
            [theme.breakpoints.down("sm")]: {
              fontSize: "14px",
              fontWeight: "400",
            },
          }}
        >
          Multi-Function Authentication
        </Typography>
        <DeededSwitcher
          disabled={!!user.deleted_at}
          activeSwitcher={isTurnedOnMfa as boolean}
          handleChangeSwitcher={handleSwitch as () => {}}
          sx={{}}
        />
      </Box>
    </>
  );
};

const PermitAccess: React.FC<{
  user: Staff;
  role: string;
  permit_access: boolean;
  handleChange: (someToChange: unknown) => void;
}> = ({user, role, permit_access, handleChange}) => {
  const isProfessional = role === "professionals";
  const isLawFirm = role === "law-firm";
  const isUserClient = user.role === "Client";
  const theme = useTheme();
  const handleSwitch = (e: {target: {checked: boolean}}) => {
    handleChange({
      target: {
        name: "permit_access",
        value: e.target.checked,
      },
    });
  };
  return (
    <>
      {role != "law-firm" ? (
          <>
          <Box
            sx={{
              display: "flex",
              width: isUserClient ? "227px" : "217px",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: isProfessional ? "33px" : 0,
              "& .MuiFormControlLabel-root": {
                marginRight: "0px",
                marginLeft: "0px",
                "& .MuiSwitch-root": {
                  margin: "0",
                },
              },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginTop: "0px",
                marginBottom: "20px",
              },
            }}
          >
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontSize: isUserClient ? "11px" : "10px",
                fontWeight: "600",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                  fontWeight: "400",
                },
              }}
            >
              Allow Access
            </Typography>
            <DeededSwitcher
              disabled={!!user.deleted_at}
              activeSwitcher={permit_access as boolean}
              handleChangeSwitcher={handleSwitch as () => {}}
              sx={{}}
            />
          </Box>
      </>
    ) : null}
    </>
  );
};

const WrapperSelect = styled(Box)({
  display: "flex",
  textTransform: "capitalize",
  backgroundColor: constants.colors.white,
  marginTop: "10px",
  "& > div > div": {
    fontSize: "12px",
    lineHeight: "15px",
  },
  input: {
    padding: "12px 14px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "15px",
    position: "absolute",
    color: constants.colors.intakeTitle,
    marginRight: 0,
  },
});
const FieldsBox = styled(Box)(({theme}) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));
const UserForm = styled("form")(({theme}) => ({
  margin: "20px -7px 0",
}));

const UserFormLeft = styled(Box)(({theme}) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0 7px",
  [theme.breakpoints.down("sm")]: {
    flex: "0 0 100%",
    marginBottom: "25px",
  },
}));

const UserFormRight = styled(Box)(({theme}) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  padding: "0 7px",
  [theme.breakpoints.down("sm")]: {
    flex: "0 0 100%",
    alignItems: "inherit",
  },
}));
const UserFormLabel = styled("label")({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "15px",
  position: "relative",
  color: constants.colors.intakeTitle,
  marginRight: 0,
  marginBottom: "25px",
  "&:last-child": {
    marginBottom: "0",
  },
  ".select": {
    marginTop: "10px",
    "&.has_error": {
      fieldset: {
        borderColor: "var(--red)",
      },
    },
  },
  "&.has_icon": {
    "&::before": {
      content: "''",
      position: "absolute",
      width: "17px",
      height: "17px",
      background: `url(${userIcon}) no-repeat`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      top: "34px",
      left: "10px",
      zIndex: 2,
      pointerEvents: "none",
    },
  },
  "&.email_icon": {
    "&::before": {
      background: `url(${emailIcon}) no-repeat center`,
    },
  },
  "&.home_icon": {
    "&::before": {
      filter: 'invert(40%)',
      background: `url(${homeIcon}) no-repeat center`,
    },
  },
  "&.phone_icon": {
    "&::before": {
      background: `url(${phoneIcon}) no-repeat center`,
    },
  },
  "&.slack_icon": {
    "&::before": {
      background: `url(${slackIcon}) no-repeat center`,
    },
  },
  "input.number_input": {
    paddingLeft: "35px",
    height: "36px",
    marginTop: "10px",
    "&.has_errorm &:focus": {
      borderColor: "var(--red)",
    },
  },
});

const UserFormInput = styled("input")({
  outline: "none",
  width: "100%",
  fontFamily: "inherit",
  height: "36px",
  background: "var(--white)",
  border: "1px solid var(--gray)",
  borderRadius: "5px",
  paddingLeft: "35px",
  marginTop: "10px",
  transition: constants.transitions,
  "&:focus": {
    border: "1px solid var(--red)",
    transition: constants.transitions,
  },
  "&.has_error": {
    borderColor: "var(--red)",
    transition: constants.transitions,
  },
  ":disabled": {
    transition: constants.transitions,
    background: "var(--gray)",
  },
});

const UserFormInputError = styled("div")({
  color: "var(--red)",
  position: "absolute",
  left: 0,
  bottom: "-16px",
});

const UserFormUploadPhotoWrap = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  alignItems: "flex-end",
  marginBottom: "33px",
  "&.avatarEditor": {
    marginBottom: "5px",
  },
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

const UserFormUploadPhotoImg = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: "104px",
    height: "104px",
    objectFit: "cover",
    margin: "0 auto 8px",
  },
  svg: {
    width: "104px",
    height: "104px",
    margin: "0 auto 8px",
  },
  "& .staff__canvas": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  "& input": {
    margin: "10px 0",
    maxWidth: 104,
  },
});

const UserFormUploadPhotoButton = styled("label")({
  position: "relative",
  zIndex: 0,
  display: "inline-block",
  padding: "6px",
  cursor: "pointer",
  border: " 1px solid var(--green)",
  background: "var(--green)",
  color: "var(--white)",
  borderRadius: "4px",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "11px",
  lineHeight: "13px",
  textAlign: "center",
  width: "104px",
  transition: constants.transitions,
  marginRight: 0,
  "& input": {
    position: "absolute",
    display: "block",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    marginTop: 0,
    opacity: 0,
    visibility: "hidden",
  },
  "&:hover": {
    border: "1px solid var(--red)",
    background: constants.colors.white,
    color: constants.colors.red,
    transition: constants.transitions,
  },
});

const UserFormButton = styled("button")({
  marginTop: "auto",
  marginBottom: "-3px",
  width: "92px",
  height: "43px",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "17px",
  background: "var(--red)",
  color: "var(--white)",
  borderRadius: "4px",
  outline: "none",
  cursor: "pointer",
  border: `1px solid ${constants.colors.red}`,
  transition: constants.transitions,
  "&:hover": {
    color: constants.colors.red,
    background: constants.colors.white,
    transition: constants.transitions,
  },
  "&.green": {
    border: " 1px solid var(--green)",
    background: "var(--green)",
    marginRight: "10px",
    "&:hover": {
      border: "1px solid var(--red)",
      background: constants.colors.white,
      color: constants.colors.red,
      transition: constants.transitions,
    },
  },
});

const NotificationsLabel = styled("label")({
  marginTop: "13px",
  marginLeft: "2px",
  marginRight: 0,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  display: "flex",
  color: constants.font,
  "&:hover": {
    transition: constants.transitions,
    cursor: "pointer",
  },
});

const DeededCheckboxWrapper = styled(Box)({
  display: "flex",
  marginLeft: "-10px",
  transition: constants.transitions,
  "& > span": {
    transition: constants.transitions,
  },
  "&:hover": {
    transition: constants.transitions,
    cursor: "pointer",
    "& > span": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      transition: constants.transitions,
      cursor: "pointer",
    },
  },
});

export default UserPopups;
