import RefreshProfilePhotoSvg from "components/Svg/RefreshProfilePhotoSvg";
import DeleteProfilePhotoSvg from "components/Svg/DeleteProfilePhotoSvg";
import useTypedSelector from "utils/hooks/useTypedSelector";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import {styled} from "@mui/material/styles";
import constants from "styles/constants";
import {useSnackbar} from "notistack";
import {
  setPositionAC,
  setProfileSetFileAC,
  setProfileSetImageAC,
  setSaveEditAC,
  setScaleAC,
} from "redux/reducers/profileReducer";
import {useDispatch} from "react-redux";
import React, {
  ChangeEvent,
  LegacyRef,
  useEffect,
  useMemo,
} from "react";
import ReactAvatarEditor from "react-avatar-editor";
import AvatarEditor from "react-avatar-editor";
import {baseURL} from "api";
import {useTheme} from "@mui/material";
import configuration from "utils/configuration";


const ProfileGeneralPagePhoto: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const currentUser = useTypedSelector(
    (state) => state.AuthReducer.currentUser,
  );

  const file = useTypedSelector((state) => state.ProfileReducer.file);
  const image = useTypedSelector((state) => state.ProfileReducer.image);
  const saveEdit = useTypedSelector((state) => state.ProfileReducer.saveEdit);
  const scale = useTypedSelector((state) => state.ProfileReducer.scale);
  const width = useTypedSelector((state) => state.ProfileReducer.width);
  const height = useTypedSelector((state) => state.ProfileReducer.height);
  const rotate = useTypedSelector((state) => state.ProfileReducer.rotate);
  const position = useTypedSelector((state) => state.ProfileReducer.position);
  const theme = useTheme();
  const allowZoomOut = useTypedSelector(
    (state) => state.ProfileReducer.allowZoomOut,
  );
  let editor = ReactAvatarEditor;

  useEffect(() => {
    if ("userpic" in currentUser) {
      if (currentUser.userpic === null) dispatch(setProfileSetImageAC(null));
    }
  }, [currentUser, dispatch]);
  useEffect(() => {
    if ("userpic" in currentUser && currentUser?.userpic !== null) {
      dispatch(
        setProfileSetImageAC(`${baseURL}/userpics/${currentUser?.userpic?.id}`),
      );
    }
  }, [currentUser, dispatch]);
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    let evt = event?.target?.files?.[0];

    if (evt?.type !== "image/jpeg" && evt?.type !== "image/png") {
      enqueueSnackbar("Invalid file type. Please upload an image.", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(evt);

    reader.onloadend = () => {
      dispatch(
        setProfileSetFileAC({
          name: evt?.name!,
          size: evt?.size!,
          type: evt?.type!,
          data: reader.result as string,
        }),
      );
    };

    reader.onerror = (event) => {
      enqueueSnackbar("Couldn't load the file", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    };
    dispatch(
      setProfileSetImageAC(URL.createObjectURL(event?.target?.files?.[0]!)),
    );
  };

  const handleNewImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file?.type !== "image/jpeg" && file?.type !== "image/png") {
      return;
    }
    dispatch(setProfileSetImageAC(URL.createObjectURL(e?.target?.files?.[0]!)));
    dispatch(setSaveEditAC(true));
  };

  const handlePositionChange = (position: {x: number; y: number}) => {
    dispatch(setPositionAC(position));
  };

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedScale = parseFloat(e.target.value);
    dispatch(setScaleAC(parsedScale));
  };
  const onClickSave = () => {
    if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      //@ts-ignore
      const canvas = editor.getImage().toDataURL();
      dispatch(setProfileSetImageAC(canvas));
      dispatch(setSaveEditAC(false));
    }
  };

  const setEditorRef = (editorRef: typeof AvatarEditor) => (editor = editorRef);

  const imageSrcProcessed = useMemo(() => {
    if (!image) {
      return null;
    }
    if (typeof image === "string" && !image.startsWith("data")) {
      return `${image}?t=${Date.now()}`;
    }
    return image;
  }, [image]);

  return (
    <Box
      // 1st way
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "78px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "0",
        },
      }}
    >
      {imageSrcProcessed !== null && !saveEdit && (
        <ProfilePhotoBox>
          <ProfilePhoto data-testid="profile-photo" src={imageSrcProcessed} alt="" />
        </ProfilePhotoBox>
      )}
      {saveEdit && (
        <ProfileCanvasBox>
          <ReactAvatarEditor
            scale={parseFloat(String(scale))}
            width={width}
            height={height}
            position={position}
            onPositionChange={handlePositionChange}
            rotate={parseFloat(String(rotate))}
            borderRadius={5}
            image={image as string | File}
            ref={setEditorRef as unknown as LegacyRef<AvatarEditor> | undefined}
          />

          <input
            style={{margin: "1rem 0"}}
            name="scale"
            type="range"
            onChange={handleScale}
            min={allowZoomOut ? "0.1" : "1"}
            max="2"
            step="0.01"
            defaultValue="1"
          />
        </ProfileCanvasBox>
      )}
      {saveEdit ? (
        <SaveEditBtn data-testid="profile-save-image-button" type="button" onClick={onClickSave}>
          Save Image
        </SaveEditBtn>
      ) : (
        <Box sx={styles.profilePhotoIcons}>
          <Button sx={styles.RefreshProfilePhotoSvg} data-testid="profile-upload-photo-btn">
            <label
              style={styles.RefreshProfilePhotoSvgLabel}
              htmlFor={"updatePhoto"}
            >
              <RefreshProfilePhotoSvg />
            </label>
          </Button>
          <input
            onChange={(e) => {
              handleImageUpload(e);
              handleNewImage(e);
            }}
            accept="image/jpeg, image/png"
            style={{
              display: "none",
            }}
            type={"file"}
            id={"updatePhoto"}
            name={"updatePhoto"}
          />
          <Button
            onClick={() => {
              dispatch(setProfileSetImageAC(null));
              dispatch(setProfileSetFileAC({}));
            }}
            sx={styles.DeleteProfilePhotoSvg}
            data-testid="profile-remove-photo-btn"
          >
            <DeleteProfilePhotoSvg stroke={constants.colors.deededGray} />
          </Button>
        </Box>
      )}
      <Typography
        data-testid="profile-full-name"
        component="p"
        sx={styles.userName}
      >
        {currentUser?.role === "Law Firm"
          ? currentUser.first_name
          : "full_name" in currentUser 
            ? currentUser.full_name
            : ""}
      </Typography>

      <Typography data-testid="profile-user-role" component="p" sx={styles.userRole}>
        {"role" in currentUser ? currentUser.role : ""}
      </Typography>
    </Box>
  );
};

// 2nd way
const ProfilePhoto = styled("img")({
  borderRadius: "50%",
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
const SaveEditBtn = styled(Button)(({theme}) => ({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.2rem",
  textTransform: "uppercase",
  lineHeight: "1.5rem",
  color: "var(--white)",
  padding: "1.2rem",
  background: "var(--green)",
  border: "none",
  borderRadius: "0.5rem",
  outline: "none",
  "&:hover": {
    outline: "1px solid var(--green)",
    color: "var(--green)",
  },
}));

const ProfilePhotoBox = styled(Box)(({theme}) => ({
  borderRadius: "50%",
  width: "176px",
  height: "176px",
  [theme.breakpoints.down("sm")]: {
    borderRadius: 0,
  },
}));
const ProfileCanvasBox = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "170px",

  "& canvas": {
    width: "170px !important",
    height: "170px !important",
  },
}));

// 3rd way
const styles = {
  RefreshProfilePhotoSvgLabel: {
    marginRight: "0px",
    cursor: "pointer",
    padding: 0,
    minWidth: 0,
  },
  profilePhotoIcons: {
    display: "flex",
    marginTop: "24px",
  },
  RefreshProfilePhotoSvg: {
    marginRight: "20px",
    cursor: "pointer",
    padding: 0,
    minWidth: 0,
  },
  DeleteProfilePhotoSvg: {
    padding: 0,
    cursor: "pointer",
    minWidth: 0,
  },
  userName: {
    marginTop: "36px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: constants.font,
  },
  userRole: {
    marginTop: "6px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#828282",
  },
} as const;

export default ProfileGeneralPagePhoto;
